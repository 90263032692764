
/*
================================================================================

  Main

================================================================================
*/

import ContactFrom from './components/contact-form';
import Navigation from './components/navigation';
import Products from './components/products';
import ProductsSlider from './components/products-slider';


ContactFrom.init();
Navigation.init();
Products.init();
ProductsSlider.init();
