
/*
================================================================================

  "Products Slider" component

================================================================================
*/

import Swiper from 'swiper';

const ProductsSlider = (() => {
  const initSlider = function(sliderContainerElem) {
    const sliderElem = sliderContainerElem.parentElement;
    const prevElem = sliderElem.querySelector('.slider-nav-btn--prev');
    const nextElem = sliderElem.querySelector('.slider-nav-btn--next');

    new Swiper(sliderContainerElem, {
      centeredSlides: true,
      spaceBetween: 15,
      slidesPerView: 1.2,
      speed: 500,
      loop: true,
      observer: true,
      observeParents: true,
      navigation: {
        prevEl: prevElem,
        nextEl: nextElem
      },
      breakpoints: {
        414: {
          spaceBetween: 20,
          slidesPerView: 1.5
        },
        568: {
          centeredSlides: false,
          spaceBetween: 20,
          slidesPerView: 2
        },
        768: {
          centeredSlides: false,
          spaceBetween: 30,
          slidesPerView: 3
        },
        1024: {
          centeredSlides: false,
          spaceBetween: 40,
          slidesPerView: 4
        }
      }
    });
  };

  const init = function() {
    if (document.querySelector('.products-slider')) {
      const productsSliderList = document.querySelectorAll('.products-slider__container');

      Array.from(productsSliderList, (slider) => {
        initSlider(slider);
      });
    }
  };

  return {
    init: init
  };

})();

export default ProductsSlider;
