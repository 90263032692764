
/*
================================================================================

  "Products" component

================================================================================
*/

const Products = (() => {
  const toggleItem = function() {
    const headerElem = this;
    const itemElem = headerElem.parentElement;

    const activeItemElem = document.querySelector('.products-item.js-expanded');

    activeItemElem.classList.remove('js-expanded');
    itemElem.classList.add('js-expanded');
  };

  const initEventListeners = function() {
    if (document.querySelector('.products')) {
      const headersList = document.querySelectorAll('.products-item__header');

      Array.from(headersList, (item) => {
        item.addEventListener('click', toggleItem);
      });
    }
  };

  const init = function() {
    initEventListeners();
  };

  return {
    init: init
  };

})();

export default Products;
