
/*
================================================================================

  "Navigation" component

================================================================================
*/

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


const Navigation = (() => {
  const toggleNavigation = function() {
    const navElem = document.querySelector('.nav');

    if (!navElem.classList.contains('js-open')) {
      disableBodyScroll(navElem);
      navElem.classList.add('js-open');
    } else {
      enableBodyScroll(navElem);
      navElem.classList.remove('js-open');
    }
  };

  const initEventListeners = function() {
    if (document.querySelector('.nav')) {
      const menuBtnElem = document.querySelector('.header__menu-btn');
      const closeBtnElem = document.querySelector('.nav__close-btn');

      menuBtnElem.addEventListener('click', toggleNavigation);
      closeBtnElem.addEventListener('click', toggleNavigation);
    }
  };

  const init = function() {
    initEventListeners();
  };

  return {
    init: init
  };

})();

export default Navigation;
