
/*
================================================================================

  "Contact Form" component

================================================================================
*/

import $ from 'jquery';

const ContactFrom = (() => {
  const initEventListeners = function() {
    $('.contact-form').submit(function(e) {
      const $form = $(this);

      e.preventDefault();

      $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: $form.serialize(),

        success: function() {
          alert('Form submitted!');

          $form.find('input, textarea').each(function() {
            $(this)
              .val('');
          });
        },

        error: function() {
          alert('Error! Try again, please!');
        }
      });
    });
  };

  const init = function() {
    initEventListeners();
  };

  return {
    init: init
  };

})();

export default ContactFrom;
